import {method} from '@/api/api';

export const actions = {
    getTrackingId({commit}, params) {
        return new Promise((resolve, reject) => {
            method('tickets.tracking.get', params).then(res => {
                commit('SET_TICKET_DETAIL', res.data)
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })
        })

    },
};
