<template>
  <main>
    <main class="menu" :class="{'open':showMenu}" @keydown.esc="showMenu=false">
      <div>
        <div class="menu-wrapper container d-flex flex-column gap-5">
          <div class="d-flex align-items-center justify-content-between">
            <router-link to="/">
              <img src="/img/logo.svg" alt="logo"/>
            </router-link>

            <i class="icon-close cursor-pointer" @click="showMenu = !showMenu"></i>
          </div>

          <div class="d-flex flex-column align-items-start gap-3">
            <router-link @click="open" to="/ticket/search" class="d-flex gap-1 align-items-center">
              <img src="../assets/img/icons/green-tracker.png" alt="tracker">
              <span class="link d-block">{{ $t('track') }}</span>
            </router-link>
            <router-link :to="{name: 'TicketCreate'}"
                         class="btn btn-primary" @click="open">
              <span class="show-content-one">{{ $t('create_ticket') }}</span>
            </router-link>
          </div>
        </div>
        <div class="overlay" @click="showMenu=!showMenu"></div>
      </div>
    </main>
    <header class="position-relative">
      <div class="container d-flex align-items-center justify-content-between">
        <router-link to="/">
          <img src="/img/logo.svg" alt="logo"/>
        </router-link>

        <div class="d-flex align-items-center gap-3 justify-content-end">
          <router-link to="/ticket/search" class="gap-1 align-items-center d-sm-flex d-none">
            <img src="../assets/img/icons/green-tracker.png" alt="tracker">
            <span class="link d-block">{{ $t('track') }}</span>
          </router-link>
          <router-link :to="{name: 'TicketCreate'}"
                       class="btn btn-primary d-sm-block d-none">
            <span class="show-content-one">{{ $t('create_ticket') }}</span>
          </router-link>
          <LanguageSwitcher/>
          <img src="../assets/img/icons/menu.png" alt="menu" @click="open" class="cursor-pointer d-sm-none d-block">
        </div>
      </div>
    </header>
  </main>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  components: {LanguageSwitcher},
  data() {
    return {
      showMenu: false
    }
  },
  watch: {
    '$route': {
      handler(val) {
        if (val) {
          this.showMenu = false
        }
      },
      deep: true
    }
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.showMenu = false
      }
    });
  },
  methods: {
    open() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>

<style>
header {
  position: sticky;
  z-index: 99;
  top: 0;
  background-color: #fff;
  padding: 1rem;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
</style>