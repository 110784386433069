<template>
  <el-dropdown trigger="click" @command="changeLanguages" class="headerLangs">
    <div class="headerLangsSelect">
      <span>{{ currentLang }} <i class="el-icon-caret-bottom"></i></span>
      <i class="icon-select-arrow"></i>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="ru">Ru</el-dropdown-item>
      <el-dropdown-item command="oz">O‘z</el-dropdown-item>
      <el-dropdown-item command="en">En</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
  </template>

<script>
import {setItem} from '@/utils/storage'

export default {
  name: 'LanguageSwitcher',
  data() {
    return {}
  },
  computed: {
    currentLang() {
      switch (this.$i18n.locale) {
        case 'oz':
          return 'O‘Z';
        case 'en':
          return 'EN';
        default:
          return 'RU';
      }
    },
  },
  created() {
  },
  methods: {
    changeLanguages(lang) {
      this.$i18n.locale = lang;
      setItem('locale', lang)
    },
  }
}
</script>
