export default {
    name: 'Full name',
    write_your_name: 'Write your name',
    phone_number: 'Phone Number',
    email: 'Email',
    write_your_email: 'Write your email',
    category: 'Category',
    select: 'Select',
    priority: 'Priority',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    subject: 'Subject',
    write_subject: 'Write subject',
    message: 'Message',
    drop_comment: 'Enter your comment here',
    attachments: 'Attachments',
    add_file: 'Add file',
    submit_ticket: 'Submit Ticket',
    close: 'Close',
    hello_applicant: 'Thank you, your request has been accepted!',
    tracked_status: 'You can track the status of your request.',
    sent_to_email: 'Ticket ID has been sent to your email address.',
    support_center: "IT Park Uzbekistan\nSupport Center",
    welcome: "Welcome to the IT Park Uzbekistan Support Center, a special platform for effective problem solving and support. We understand that every challenge is unique, which is why we are here to tailor solutions to your specific needs. Tell us what you need help with.",
    find_ticket: "Find my created ticket",
    find: "Search",
    create_ticket: "Create a new ticket",
    back: "Back",
    created_at: 'Created at',
    updated_at: 'Updated at',
    ticket_detail: 'Ticket details',
    ticket_id: 'Ticket ID',
    go_home: 'Go to home page',
    check_captcha: 'Check the captcha',
    fill_form: 'Please fill the form',
    went_wrong: 'Something went wrong',
    status: 'Status',
    assigned_department: 'Responsible department',
    deadline: 'Deadline',
    day: 'day',
    replies: 'Replies',
    attached: 'Attached file',
    faq: 'Frequently asked questions',
    find_answers: 'Find answers to common questions',
    no_answers: 'Answer not found',
    view_ticket: 'View your ticket ',

    no_article: 'Articles not found',
    search_ticket: 'Search ticket here',
    enter_ticket_id: 'Enter ticket ID',
    search_question: 'Search your question...',
    search_results: 'Search results',
    track: 'Ticket status',
    copied: 'Copied',
    search: 'Search',
    application_to: 'Application to IT Park'
}
