import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
    // registration props here
    position: 'top-right',
    maxToasts: 10,
    transition: 'Vue-Toastification__bounce',
    transitionDuration: 750, //ms
    draggable: true,
    draggablePercent: 0.6,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    closeOnClick: true,
    timeout: 5000,
    container: document.body,
    hideProgressBar: false,
    hideCloseButton: false,
    icon: true
});