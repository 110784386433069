<template>
  <div id="app">
    <Header/>
    <router-view/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  components: {Header},
  data(){
    return {
    }
  },
  created() {
  },
  computed: {},
  methods: {}
};
</script>