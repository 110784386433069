export default {
    name: 'ФИО',
    write_your_name: 'Напишите свое имя',
    phone_number: 'Номер телефона',
    email: 'Электронная почта',
    write_your_email: 'Напишите свою электронную почту',
    category: 'Категория',
    select: 'Выберите',
    priority: 'Приоритет',
    low: 'Низкий',
    medium: 'Средний',
    high: 'Высокий',
    subject: 'Тема',
    write_subject: 'Написать тему',
    message: 'Сообщение',
    drop_comment: 'Введите свой комментарий здесь',
    attachments: 'Вложения',
    add_file: 'Добавить файл',
    submit_ticket: 'Отправить тикет',
    close: 'Закрыть',
    hello_applicant: 'Спасибо, ваше обращение принято в работу!',
    tracked_status: 'Вы можете отслеживать статус обращения.',
    sent_to_email: 'ID тикета отправлено на вашу указанную электронную почту.',
    support_center: "Центр поддержки \n IT Park Uzbekistan",
    welcome: "Добро пожаловать в Центр поддержки IT Park Uzbekistan, специальную платформу для эффективного решения проблем и поддержки. Мы понимаем, что каждая задача уникальна, и поэтому мы здесь, чтобы адаптировать решения к вашим конкретным потребностям. Расскажите нам, в чем вам нужна помощь.",
    find_ticket: "Найти мой созданный тикет",
    find: "Найти",
    create_ticket: "Создать новый тикет",
    back: "Назад",
    created_at: 'Дата создания',
    updated_at: 'Дата редактирования',
    ticket_detail: 'Информация о тикете',
    ticket_id: 'ID тикета',
    go_home: 'Перейти на главную страницу',
    check_captcha: 'Заполните капчу',
    fill_form: 'Пожалуйста, заполните форму',
    went_wrong: 'Что-то пошло не так',
    status: 'Статус',
    assigned_department: 'Ответственный отдел',
    deadline: 'Срок задачи',
    day: 'дней',
    replies: 'Ответы',
    attached: 'Прикреплённый файл',
    faq: 'Часто задаваемые вопросы',
    find_answers: 'Найдите ответы на распространенные вопросы',
    no_answers: 'Ответ не найден',
    view_ticket: 'Посмотрите свой тикет',

    no_article: 'Статьи не найдены',
    search_ticket: 'Поиск тикета',
    enter_ticket_id: 'Введите id тикета',
    search_question: 'Поиск статьи',
    search: 'Поиск',
    track: 'Статус тикета',
    copied: 'Скопировано',
    search_results: 'Результаты поиска',
    application_to: 'Обращение в IT Park'
};