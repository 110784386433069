export default {
    name: 'F.I.SH.',
    write_your_name: 'Ismingizni yozing',
    phone_number: 'Telefon raqami',
    email: 'Elektron pochta',
    write_your_email: 'Elektron pochtangizni yozing',
    category: 'Kategoriya',
    select: 'Tanlang',
    priority: 'Ustuvorlik',
    low: 'Past',
    medium: 'O\'rtacha',
    high: 'Yuqori',
    subject: 'Mavzu',
    write_subject: 'Mavzuni yozing',
    message: 'Xabar',
    drop_comment: 'Xabaringizni shu yerga kiriting',
    attachments: 'Qo\'shimchalar',
    add_file: 'Fayl qo\'shish',
    submit_ticket: 'Tiketni yuborish',
    close: 'Yopish',
    hello_applicant: 'Rahmat, sizning murojaatingiz qabul qilindi!',
    tracked_status: 'Siz murojaatingiz holatini kuzatib borishingiz mumkin.',
    sent_to_email: 'Tiket ID ko\'rsatilgan elektron pochta manzilingizga yuborildi.',
    support_center: "IT Park Uzbekistan \n qoʻllab-quvvatlash markazi",
    welcome: "IT Park Uzbekistan qoʻllab-quvvatlash markaziga xush kelibsiz, muammolarni samarali hal qilish va qoʻllab-quvvatlash uchun maxsus platforma. Biz har bir savol yoki muammo noyob ekanligini tushunamiz, shuning uchun biz sizning maxsus ehtiyojlaringizga yechimlarni moslashtirish uchun shu yerdamiz. Sizga nima yordam kerakligini yozib yuboring.",
    find_ticket: "Mening yaratilgan tiketimni topish",
    find: "Izlash",
    create_ticket: "Yangi tiket yaratish",
    back: "Orqaga",
    created_at: 'Yaratilgan vaqti',
    updated_at: 'Tahrirlangan vaqti',
    ticket_detail: 'Tiket ma\'lumotlari',
    ticket_id: 'Tiket ID',
    go_home: 'Bosh sahifaga o\'tish',
    check_captcha: 'Captcha-ni to\'ldiring',
    fill_form: 'Iltimos, tiketni toʻldiring',
    went_wrong: 'Nimadir noto\'g\'ri bajarildi',
    status: 'Status',
    assigned_department: 'Mas\'ul bo\'lim',
    deadline: 'Vazifani bajarish muddati',
    day: 'kun',
    replies: 'Javoblar',
    attached: 'Biriktirilgan fayl',
    faq: 'Tez-tez so\'raladigan savollar',
    find_answers: 'Keng tarqalgan savollarga javob toping',
    no_answers: 'Javob topilmadi',
    view_ticket: 'O\'z tiketimni ko\'rish',

    no_article: 'Maqolalar topilmadi',
    search_ticket: 'Tiketni izlash',
    enter_ticket_id: 'Tiket id raqamini kiriting',
    search_question: 'Maqolani izlash',
    search: 'Izlash',
    track: 'Tiket statusi',
    copied: 'Kopiya qilindi',
    search_results: 'Izlash natijalari',
    application_to: 'IT Parkga murojaatnoma'
};
