import {method} from "@/api/api";

export const actions = {
    getList({commit}, params) {
        return new Promise((resolve, reject) => {
            method('help.articles.published', params).then(res => {
                commit('SET_ARTICLES', res.data)
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
    }
}