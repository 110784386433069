import Vue from 'vue'
import Vuex from 'vuex'
import ticket from "@/store/ticket";
import category from "@/store/category";
import faqCategories from "@/store/faqCategories";
import articles from "@/store/articles";

Vue.use(Vuex)


const store = new Vuex.Store({
    modules: {
        ticket,
        category,
        faqCategories,
        articles
    },
});

export default store;