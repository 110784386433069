import axios from 'axios';
import {getToken, clearForLogout} from '@/utils/auth';
import store from '@/store';
import router from '@/router';
import {errorsAlert} from './requestAlerts';

const service = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 300000 // request timeout
});
service.interceptors.request.use(
    config => {
        // loaderIncrease(config);
        let token = getToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        // loaderDecrease(response.config);
        const res = response.data;
        if (response.status !== 200) {
            return Promise.reject(new Error(res.message || 'Error'));
        } else {
            return res;
        }
    },
    error => {
        errorsAlert(error.response);
        return Promise.reject(error.response);
    }
);


const notLoader = [
    'classifiers.position'
];

function getRequestBodyMethod(config) {
    return config['data']['method'] ? config['data']['method'] : null;
}

function loaderIncrease(config) {
    if (notLoader.indexOf(getRequestBodyMethod(config)) < 0) {
        store.dispatch('loader/increase');
    }
}

function loaderDecrease(config) {
    if (notLoader.indexOf(getRequestBodyMethod(config)) < 0) {
        store.dispatch('loader/decrease');
    }
}

export default service;
