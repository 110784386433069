import {
    cloneObject as utilsCloneObject, 
    orderBy as utilsOrderBy 
} from '@/utils/index'
export default {
    data() {
        return {
            screenWidth: 1400,
        }
    },
    computed: {
    },
    created(){
        this.screenWidth = window.innerWidth; 
        window.addEventListener('resize', this.resizeScreen);
    },
    unmounted() {
        window.removeEventListener('resize', this.resizeScreen);
    },
    methods: {
        resizeScreen(){
            this.screenWidth = window.innerWidth;
        },
        cloneObject(object) {
            return utilsCloneObject(object);
        },
        orderBy(data, field, type = 'asc', byLength = false) {
            return utilsOrderBy(data, field, type, byLength);
        },
        changeQuery(key, value) {
            let query = {... {}, ...this.$route.query };
            if (value) {
                if (query[key] != value) {
                    query[key] = value;
                    this.$router.push({ name: this.$route.name, params: this.$route.params, query });
                }
            } else {
                delete query[key];
                this.$router.push({ name: this.$route.name, params: this.$route.params, query });
            }
        },
        openFile(base64, filename) {
            let link = document.createElement("a");
            link.href = base64;
            link.download = filename;
            // link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        changeQuery(key, value) {
            let query = {... {}, ...this.$route.query };
            if (value) {
                if (query[key] != value) {
                    query[key] = value;
                    this.$router.push({ name: this.$route.name, params: this.$route.params, query });
                }
            } else {
                if (query[key]) {
                    delete query[key];
                    this.$router.push({ name: this.$route.name, params: this.$route.params, query });
                }
            }
        },
        getMonthName(date){
            const monthNames = [
                this.$t("Январь"), 
                this.$t("Февраль"), 
                this.$t("Март"), 
                this.$t("Апрель"), 
                this.$t("Май"), 
                this.$t("Июнь"),
                this.$t("Июль"), 
                this.$t("Август"), 
                this.$t("Сентябрь"), 
                this.$t("Октябрь"), 
                this.$t("Ноябрь"), 
                this.$t("Декабрь")
            ];
            var result = new Date(date);
            return monthNames[result.getMonth()];
        },
        getDay(date){
            var result = new Date(date);
            return result.getDate();
        },
        getYear(date){
            var result = new Date(date);
            return result.getFullYear();
        },
        getYearRu(n){
            let str1 = this.$t('год');
            let str2 = this.$t('года');
            let str5 = this.$t('лет');
            return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
        },
        getAge(n){
            let str1 = this.$t('год в');
            let str2 = this.$t('года в');
            let str5 = this.$t('лет в');
            return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
        },
        getMonthRu(n){
            let str1 = this.$t('месяц');
            let str2 = this.$t('месяца');
            let str5 = this.$t('месяцев');
            return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
        },
        even(x){
            if(Number.isInteger(x) == true){return x % 2 == 0}else{return undefined}
        },
        getIsHackathonDay(){
            if(this.hackathonStartDate){
                var currentDate = this.$moment(this.$moment().format('YYYY-MM-DD'));
                var currentDateDiff = currentDate.diff(this.$moment(this.hackathonStartDate), 'days');
                return currentDateDiff < 7 && currentDateDiff >= 0;
            }
            return false;
        },
        getTotalBalls(scores){
          var total = 0;
          if(scores && scores.length){
            scores.forEach(score => {
                if(score.calc_percent){
                    total += (score.ball*score.calc_percent);
                }else{
                    total += score.ball;
                }
            });
          }
          return total.toFixed(2);
        },
        getRoleT(role){
            switch (role) {
                case 'jury':
                    return this.$t('Жюри неотобранные');
                case 'jury-main':
                    return this.$t('Жюри отобранные');
                default:
                    return this.$t(role);
            }
        },
        regionNameBySoato(soato){
            var model = this.regions.find(item => item.soato == soato);
            if(model){
                switch (this.$i18n.locale) {
                case 'uz':
                    return model.title_cl;
                case 'oz':
                    return model.title_uz;
                case 'en':
                    return model.title_en;
                default:
                    return model.title;
                }
            }
            return "";
        },
        getAbb(text){
            var res = "";
            if(text && text.length){
                var textSplit = text.split(' ');
                res = Array.from(textSplit[0])[0];
                if(textSplit.length > 1){
                    res += Array.from(textSplit[1])[0];
                }
            }
            return res;
        },
        getDayRu(n){
          let str1 = this.$t('день');
          let str2 = this.$t('дня');
          let str5 = this.$t('дней');
          return this.getNSTextRu(n, str1, str2, str5);
        },
        getNSTextRu(n, str1, str2, str5){
          return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
        },
        getTree(list, parent_id) {
            if (list && list.length) {
              list.map(item => {
                item['has_parent'] = !!item['parent_id'];
                if(item['parent_id']){
                    var parent = list.find(i => i.id == item['parent_id']);
                    item['has_parent'] = !!parent;    
                }
                return item;
              });
              if (parent_id) {
                  return list.filter(child => child.parent_id == parent_id).map(item => {
                      return {
                          value: String(item.id),
                          label: item.name,
                          filial_id: item.filial_id,
                          children: this.getTree(list, item.id),
                      }
                  })
              } else {
                  return list.filter(parent => !parent.has_parent).map(item => {
                      return {
                          value: String(item.id),
                          label: item.name,
                          filial_id: item.filial_id,
                          children: this.getTree(list, item.id),
                      }
                  })
              }
          }
          return [];
        },
        getExtensionFromFileName(filename){
            var filenameArr = filename.split('.');
            return filenameArr.slice(-1)[0];
        },
        getFullPath(path){
            return `${process.env.VUE_APP_PUBLIC}/itparktickets/storage/${path}`;
        }
    }
};