import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Main",
        component: () => import("@/components/Main.vue"),
    },
    {
        path: "/ticket-create",
        name: "TicketCreate",
        component: () => import("@/components/FormTicket.vue"),
    },
    {
        path: "/ticket/show",
        name: "TicketShow",
        component: () => import("@/components/Ticket.vue"),
    },
    {
        path: "/ticket/search",
        name: "TicketSearch",
        component: () => import("@/components/SearchTicket.vue"),
    },
    {
        path: "/articles",
        name: "Articles",
        component: () => import("@/components/article/index.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (!to.meta["savePosition"]) {
            return {x: 0, y: 0};
        }
    },
});

router.beforeEach((to, from, next) => {
    return next();
});

export default router;
